import { showToast, useAppStore } from '@/stores/app';
import { ToastType } from '@/types/toast';
import axios, { AxiosError } from 'axios';
import { getSession } from 'next-auth/react';
import { getUtmData, shouldReportUtmData } from '@/utils/cookie';

const isServer = typeof window === 'undefined';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async config => {
    if (isServer) {
      try {
        const { cookies } = await import('next/headers'),
        token = cookies().get('token')?.value;

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } catch (error) {
        // There are case this might error 
        // One example is the /api/check_email_for_verification,
        // this is one endpoint used as REST API, and in turns it will call
        // backend api /user/email_verified/{email} without request context.
        // In this case, cookie is not accessible. So we just pass.
        if (!config.url?.includes('/api/check_email_for_verification')) {
          console.error(`[ERROR] config.url=${config.url} with error=${error}`);
        }
      }
    } else {
      try {
          const session = await getSession();
          const provider = session?.provider;
          const token = (provider === 'google' || provider === 'credentials') ? session?.idToken : session?.accessToken;
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }

          if (provider) {
            config.headers.Provider = provider;
          }

          if (shouldReportUtmData()) {
            const utmData = getUtmData();
            if (utmData) {
              config.headers['X-UTM-Data'] = JSON.stringify(utmData);
            }
          }
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => response.data,
  async error => {
    // const originalRequest = error.config;
    // Only log out if the user is already authenticated
    const session = useAppStore.getState().session;
    const isSignedIn = session !== null;
    if (error.response?.status === 401 && isSignedIn) {
      const currentUrl = new URL(window.location.href);
      const hasAuthQuery = currentUrl.searchParams.has('auth_required');
      const options = window && !hasAuthQuery
      ? {
          callbackUrl: currentUrl.searchParams.size > 0
            ? `${window.location.href}&auth_required=true`
            : `${window.location.href}?auth_required=true`
        }
      : undefined;
      useAppStore.getState().logOut(options);
    } else {
      showToast(`Error: ${error?.response?.data?.detail || error?.message}`, ToastType.ERROR);
    }
    // if (error.response?.status === 401 && !originalRequest._retry) {
    //   console.error('GOT 401: ', originalRequest);

    //   originalRequest._retry = true;
    //   // TODO causes an infinity login loop becuase discrod auth is not yet ready backendside
    //   // await signIn(originalRequest.headers.Provider);
    //   const session = await getSession();

    //   if (session) {
    //     api.defaults.headers.common.Authorization = `Bearer ${session.accessToken}`;
    //   }
    //   return api(originalRequest);
    // }
    return Promise.reject(error);
  },
);


export const publicApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

publicApi.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

export async function callApi<Response, Error>(action: Promise<Response>): Promise<Response | AxiosError<Error>> {
  try {
    return await action;
  } catch (err) {
    return err as AxiosError<Error>;
  }
}

export async function callApiOrThrow<Response, Error>(action: Promise<Response>): Promise<Response> {
  const result = await callApi<Response, Error>(action);
  if (axios.isAxiosError(result)) {
    throw result;
  } else {
    return result as Response;
  }
}

export default api;
