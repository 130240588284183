import { callWithLoading, stateBase } from '@/helpers/store';
import { StateBase } from '@/types/store';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import productsApi from '@/api/products';
import { Product, Promotion, ClaimDailyGemsResponse, PaymentCheckoutResponse, VerifyPurchaseResponse, GetProductsAndPromotionsResponse } from '@/types/products';
import { UserSubscription } from '@/models/user';
import { useUserStore } from './user';
import { openModal } from '@/components/BaseModal';
import { Modals } from '@/types/modals';
import { SubscriptionPaymentMethod, PaymentMethod, ActivePaymentProvider, PaymentProvider } from '@/payment';


export interface ProductsState extends StateBase {
  products: Product[];
  promotions: Promotion[];
  selectedProductId: number | null;
  selectedCheckoutSession: PaymentCheckoutResponse | null;
  claimDailyGemsLoading: boolean;
  subscriptionPromotionEndTime: number | null;

  hasFreeGems: () => boolean;
  getProductsAndPromotions: () => void;
  getNextPromotion: () => Promotion | null;
  claimDailyGems: () => void;
  checkoutProduct: (product_id: number, base_url?: string) => Promise<boolean>;
  checkoutProductPaypal: (product_id: number, base_url?: string) => Promise<boolean>;
  checkoutProductCCBill: (product_id: number, base_url?: string) => Promise<boolean>;
  checkoutProductStripe: (product_id: number, base_url?: string) => Promise<boolean>;
  verifyPurchase: (first_id: string, second_id?: string) => Promise<boolean>;
  cancelSubscription: (notes?: string) => Promise<boolean>;
  clearSelectedProduct: () => void;
  reset: () => void;
}

// const SUBSCRIPTION_PROMO_TIMER = Date.now() + 3600000;
const SUBSCRIPTION_PROMO_TIMER = null;

export const useProductsStore = create<ProductsState>()((set, get) => ({
  ...stateBase,
  products: [],
  promotions: [],
  selectedProductId: null,
  selectedCheckoutSession: null,
  claimDailyGemsLoading: false,
  subscriptionPromotionEndTime: SUBSCRIPTION_PROMO_TIMER,
  hasFreeGems: () => {
    const products = get().products;
    return products.some(product => product.usd_cents === 0 && (product.remaining_time == null || product.remaining_time <= 0));
  },
  getNextPromotion: () => {
    const promotions = get().promotions;
    if (!promotions || promotions.length === 0) return null;
    const validPromotions = promotions.filter(p => p && p.remaining_time && p.remaining_time > 0);
    return validPromotions.reduce((closest, promotion) => {
      if (closest === null || (promotion.remaining_time ?? Infinity) < (closest.remaining_time ?? Infinity)) {
        return promotion;
      }
      return closest;
    }, null as Promotion | null);
  },
  getProductsAndPromotions: async () => {
    const res = await callWithLoading<GetProductsAndPromotionsResponse | AxiosError<any, any>>(productsApi.getProductsAndPromotions(ActivePaymentProvider), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get products: ${res.response?.data?.detail || res.message}`, ToastType.ERROR);
    } else {
      // const sortedProducts = res?.products.sort((a, b) => (a.sort_rank?? 0) - (b.sort_order ?? 0) || (a.usd_cents ?? 0) - (b.usd_cents ?? 0));
      set({ products: res?.products, promotions: res?.promotions });
    }
  },
  claimDailyGems: async () => {
    set({ claimDailyGemsLoading: true });
    const res = await callWithLoading<ClaimDailyGemsResponse | AxiosError<any, any>>(productsApi.claimDailyGems(), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get daily gems: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      useUserStore.getState().setUserStats({ gem_balance: res?.gem_balance });
      if (res?.amount_granted && res.amount_granted > 0) {
        openModal(Modals.SuccessPayment);
      }
      set(state => {
        const updatedProducts = state.products.map(product => {
          if (product.usd_cents === 0) {
            return { ...product, remaining_time: res?.remaining_time, active: false };
          }
          return product;
        });
        return { ...state, products: updatedProducts };
      });
    }
    set({ claimDailyGemsLoading: false });
  },
  checkoutProductStripe: async (product_id: number, base_url?: string) => {
    set({ selectedProductId: product_id });
    const res = await callWithLoading<PaymentCheckoutResponse | AxiosError<any, any>>(
      productsApi.checkoutProductStripe(product_id, base_url),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Error: ${res.response?.data.detail || res?.message || '`Can\'t get purchase info.'}`, ToastType.ERROR);
      return false;
    } else {
      set({ selectedCheckoutSession: res });
      return true;
    }
  },
  checkoutProductPaypal: async (product_id: number, base_url?: string) => {
    set({ selectedProductId: product_id });
    const res = await callWithLoading<PaymentCheckoutResponse | AxiosError<any, any>>(
      productsApi.checkoutProductPaypal(product_id, base_url),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Error: ${res.response?.data.detail || res?.message || '`Can\'t get purchase info.'}`, ToastType.ERROR);
      return false;
    } else {
      set({ selectedCheckoutSession: res });
      return true;
    }
  },  
  checkoutProductCCBill: async (product_id: number, base_url?: string) => {
    set({ selectedProductId: product_id });
    const res = await callWithLoading<PaymentCheckoutResponse | AxiosError<any, any>>(
      productsApi.checkoutProductCCBill(product_id, base_url),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Error: ${res.response?.data.detail || res?.message || '`Can\'t get purchase info.'}`, ToastType.ERROR);
      return false;
    } else {
      set({ selectedCheckoutSession: res });
      return true;
    }
  },    
  checkoutProduct: async (product_id: number, base_url?: string) => {
    if (ActivePaymentProvider === PaymentProvider.PAYPAL) {
      return await get().checkoutProductPaypal(product_id, base_url);
    } else if (ActivePaymentProvider === PaymentProvider.CCBILL) {
      return await get().checkoutProductCCBill(product_id, base_url);
    } else if (ActivePaymentProvider === PaymentProvider.STRIPE) {
      return await get().checkoutProductStripe(product_id, base_url);
    }
    else {
      console.error('Unsupported payment method: ', SubscriptionPaymentMethod);
      return false;
    }
  },
  verifyPurchase: async (first_id: string, second_id?: string) => {
    let res: VerifyPurchaseResponse | AxiosError<any, any> | null | undefined = null;
    if (SubscriptionPaymentMethod === PaymentMethod.CCBILL) {
      res = await callWithLoading<VerifyPurchaseResponse | AxiosError<any, any>>(
        // CCBILL: First id is order_id, second id is form_digest
        productsApi.verifyPurchaseCCBill(first_id, second_id || ''),
        set,
      );
    }
    else if (SubscriptionPaymentMethod === PaymentMethod.PAYPAL) {
      res = await callWithLoading<VerifyPurchaseResponse | AxiosError<any, any>>(
        // PAYPAL: First id is order_id, second id is subscription_id
        productsApi.verifyPurchasePaypal(first_id, second_id || ''),
        set,
      );
    } else {
      res = await callWithLoading<VerifyPurchaseResponse | AxiosError<any, any>>(
        // First id is session_id
        productsApi.verifyPurchaseStripe(first_id),
        set,
      );
    }
    if (res instanceof AxiosError) {
      showToast(`Error: ${res.response?.data.detail || res?.message || '`Can\'t get purchase info.'}`, ToastType.ERROR);
      return false;
    } else {
      console.log('[DEBUG] verifyPurchase - res', res);
      if (res?.status === 'succeeded') {
        useUserStore.getState().setUserStats({ gem_balance: res?.gem_balance });
        return true;
      }
      return false;
    }
  },
  cancelSubscription: async (notes?: string) => {
    const res = await callWithLoading<UserSubscription | AxiosError<any, any>>(
      productsApi.cancelSubscription(notes),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Can\'t cancel subscription: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else {
      useUserStore.getState().setUserStats({ subscription: res });
      return true;
    }
  },
  clearSelectedProduct: () => {
    set({ selectedProductId: null });
  },
  reset: async () => set({ ...stateBase }),
}));
