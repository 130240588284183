
export enum ProductType {
  DailyGem = 'daily_gem',
  GemBundle = 'gem_bundle',
  Subscription = 'subscription',
}

export interface Product {
  id: number;
  name: string;
  type: ProductType;
  usd_cents: number;
  gem_amt: number;
  active: boolean;
  payment_ref_id: string;
  payment_link: string;
  remaining_time?: number; 
  asset: string;
  original_price?: number;
  promotion_label?: string;
  description?: string;
  checkout_name?: string;
  checkout_amount?: number;
  checkout_description?: string;
}

export interface Promotion {
  id: number;
  name?: string;
  description?: string;
  remaining_time?: number;
}

export type ClaimDailyGemsResponse = {
  amount_granted: number;
  gem_balance: number;
  remaining_time: number;
  remaining_time_str: string;
};

export type PaymentCheckoutResponse = {
  payment_gateway: string;
  client_secret?: string;
  url?: string;
  session_id?: string;
  order_id?: string;
  payment_url?: string;
};

export type VerifyPurchaseResponse = {
  status: string;
  gem_balance: number;
};

export type GetProductsAndPromotionsResponse = {
  products: Product[];
  promotions: Promotion[];
};
