import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { UnlockButton } from '@/components/buttons/UnlockButton';
import { BaseModal, closeModal } from '../BaseModal';
import CloseIcon from '@/assets/icons/close-icon.svg';
import { MessagesSquare, ImagePlus, BadgePercent, ShieldCheck, OctagonPause, GemIcon } from 'lucide-react';
import { useCharactersStore } from '@/stores/characters';
import { useUserStore } from '@/stores/user';
import LocalStorageUtils from '@/utils/storage';
import {
  PAYMENT_PROVIDER_CHANGED_SHOWN,
  RENEWAL_REMINDER_LAST_SHOWN,
} from '@/contants/storageKeys';

/*
  Renewal reminder will show up in the following cases:
  #1. When the user uses STRIPE, which is not a valid payment provider any more, and the subscription is about to expire or expired:
    - If shows the info about payment provider chnage, once.
    - It shows a timer and a list of benefits of renewing the subscription. once every 24 hours
  #2. When user use CCBILL subscription, and the subscription is about to expire and status is cancelled:
      - Recall, when status is active, it means the subscription will auto-renew.
      - It shows a timer and a list of benefits of renewing the subscription.
  #
*/

interface RenewalReminderModalProps {
  showPaymentProviderChanged: boolean;
  showRenewalReminder: boolean;
}

export const RenewalReminderModal: React.FC<RenewalReminderModalProps> = ({ showPaymentProviderChanged, showRenewalReminder }) => {
  const { push } = useRouter();

  const characters = useCharactersStore.getState().characters;
  const character = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  }, [characters]);

  const { userStats } = useUserStore((s) => ({ userStats: s.userStats }));
  const userSubscription = userStats.subscription;
  
  const timeToExpire = useMemo(() => {
    if (!userSubscription?.current_period_end) return null;
    return new Date(userSubscription.current_period_end).getTime() - new Date().getTime();
  }, [userSubscription?.current_period_end]);

  const { timeLeft, timeStr } = useCountdownTimer(
    Math.max(timeToExpire? timeToExpire/1000 : 0, 0),
    () => {
      if (timeToExpire && timeToExpire <= 0) {
        closeModal(Modals.RenewalReminder);
      }
    },
    30000
  );

  // Split the time string and remove any suffixes
  const timeParts = timeStr.split(':').map((part) => part.replace(/[a-zA-Z]/g, ''));
  const [day, hour, min, sec] =
    timeParts.length === 4
      ? timeParts
      : timeParts.length === 3
      ? ['0', ...timeParts]
      : ['0', '0', ...timeParts];

  
  const saveLastShownTime = () => {
    LocalStorageUtils.setItem(RENEWAL_REMINDER_LAST_SHOWN, new Date().getTime());
    if (showPaymentProviderChanged) {
      LocalStorageUtils.setItem(PAYMENT_PROVIDER_CHANGED_SHOWN, true);
    }
  };

  async function onRenew() {
    push('/premium');
    onClose();
  }

  function onClose() {
    closeModal(Modals.RenewalReminder);
    saveLastShownTime();
  }

  const renderSubscriptionBenefit = () => {
    return (
      <div className="flex flex-col w-full mt-1">
        <div className="hidden sm:flex flex-col w-full items-left justify-left mt-6 mb-3">
          <Text
            className="text-white opacity-80 w-11/12 items-left text-2xl leading-[1.5]"
            variant="h2"
          >
            <span className="text-pink">Renew Now</span>{' '}
            <span className="text-white">to avoid missing out!</span>
          </Text>
        </div>

        <div className="flex flex-col w-full items-left justify-left mt-6 mb-3 sm:hidden">
          {renderRenewalSubscriptionTimer()}
        </div>

        <div className="relative flex flex-col w-full ">
          <div>
            <Text className="text-white opacity-50 w-11/12 mt-1 mb-3" variant="h6">
              Renew now so you can:
            </Text>

            <div className="flex flex-row w-full items-center mb-2">
              <MessagesSquare className="w-6 h-6 mr-2 text-pink" />
              <Text
                className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                variant="h5"
              >
                Continue to chat with your AI and generate spicy pics 🌶️
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <ImagePlus className="w-6 h-6 mr-2 text-pink" />
              <Text
                className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                variant="h5"
              >
                Keep all of your chat history and personalized characters
              </Text>
            </div>

            <div className="flex flex-row w-full items-center mb-2">
              <BadgePercent className="w-6 h-6 mr-2 text-pink" />
              <Text
                className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                variant="h5"
              >
                Take advantage of a renewal discount up to 70%
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubscriptionRenewalReminder = () => {
    return (
      <div className="flex flex-col h-full items-center justify-end sm:justify-between p-5 text-left">
        <div className="flex flex-col items-left justify-self-end mt-5 mb-8">
          {renderSubscriptionBenefit()}
        </div>

        <div className="flex w-full items-center justify-center mb-4">
          <UnlockButton
            className="w-9/12 items-center"
            wrapperClassName="py-[12px]"
            onClick={onRenew}
            title={'💎 Renew Now'}
            amount={null}
          />
        </div>
      </div>
    );
  };

  const renderRenewalSubscriptionTimer = () => {
    return (
      <div className="sm:absolute bottom-0 inset-x-0 flex flex-col w-full items-center justify-center p-5 sm:bg-gradient-to-b from-black/0 via-black/75 to-black/100">
        {showRenewalReminder && (
          <Text
            className="text-white w-full items-center justify-center mt-40 mb-2 text-2xl text-center"
            variant="h4"
          >
            {timeToExpire && timeToExpire > 0 ? 'Premium membership ends in' : 'Premium membership expired'}
          </Text>
        )}

        {showRenewalReminder && timeToExpire && timeToExpire > 0 && (
          <div className="bg-black-100 rounded-xl px-2 sm:px-3 py-1 flex items-center border-[1px] border-white/25 w-[250px]">
            <div className="flex m-1 mx-1 px-1 space-x-1 w-full justify-between">
              {/* Days */}
              <div className="flex flex-row items-end">
                <span className="text-white font-mono text-3xl font-bold mr-1">
                  {day}
                </span>
                <span className="text-white/50 text-sm -mt-1 mb-1">Day</span>
              </div>

              {/* hours */}
              <div className="flex flex-row items-end">
                <span className="text-white font-mono text-3xl font-bold mr-1">
                  {hour}
                </span>
                <span className="text-white/50 text-sm -mt-1 mb-1">Hr</span>
              </div>

              {/* Minutes */}
              <div className="flex flex-row items-end">
                <span className="text-white font-mono text-3xl font-bold mr-1">
                  {min}
                </span>
                <span className="text-white/50 text-sm -mt-1 mb-1">Min</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPaymentProviderChanged = () => {
    return (
      <div className="flex flex-col h-full items-center justify-end sm:justify-between p-5 text-left">
        <div className="flex flex-col items-left justify-self-end mt-5 mb-8">
          <div className="flex flex-col w-full mt-1">
            <div className="flex flex-col w-full items-left justify-left mt-6 mb-3">
              <Text
                className="text-white opacity-80 w-11/12 items-left text-2xl leading-[1.5]"
                variant="h3"
              >
                <span className="text-pink">Important: </span>{' '}
                <span className="text-white">We've changed payment provider</span>
              </Text>
            </div>
            <div className="relative flex flex-col w-full ">
              <div>
                <Text
                  className="text-white opacity-50 w-11/12 mt-1 mb-3"
                  variant="h6"
                >
                  What you need to know:
                </Text>

                <div className="flex flex-row w-full items-center mb-2">
                  <ShieldCheck className="w-6 h-6 mr-2 text-pink" />
                  <Text
                    className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                    variant="h5"
                  >
                    We've moved to CCBill, a more secure and reliable payment provider.
                  </Text>
                </div>

                <div className="flex flex-row w-full items-center mb-2">
                  <OctagonPause className="w-6 h-6 mr-2 text-pink" />
                  <Text
                    className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                    variant="h5"
                  >
                    Your subscription will remain active until its end date. <span className="font-bold text-pink">It will not be automatically renewed.</span>
                  </Text>
                </div>

                <div className="flex flex-row w-full items-center mb-2">
                  <GemIcon className="w-6 h-6 mr-2 text-pink" />
                  <Text
                    className="text-white w-11/12 mt-1 font-normal text-[0.9rem]"
                    variant="h5"
                  >
                    Please subscribe again after your subscription ends in order to remain a premium member.
                  </Text>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full items-center justify-center mb-4">
          <UnlockButton
            className="w-9/12 items-center"
            wrapperClassName="py-[12px]"
            onClick={onRenew}
            title={'💎 Renew Now'}
            amount={null}
          />
        </div>
      </div>
    );
  };

  return (
    <BaseModal
      className={cn(
        'h-full overflow-x-hidden overflow-y-scroll sm:h-auto sm:overflow-auto'
      )}
      id={Modals.RenewalReminder}
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col sm:flex-row items-center justify-center relative bg-black-100 rounded-3xl border border-gray-700 overflow-hidden h-[720px] sm:h-[520px] max-w-[calc(100vw-20px)] max-h-[calc(100vh-20px)]">
          <div className="w-[400px] max-w-full h-[300px] flex sm:hidden">
            {character?.avatar_url && (
              <Image
                src={character.avatar_url}
                alt={character.name || ''}
                layout="fill"
                objectFit="cover"
                className="w-full h-full object-top"
              />
            )}

            <div className="absolute bottom-0 inset-x-0 flex flex-col w-full h-full items-center justify-center bg-gradient-to-b from-black/0 via-black/75 to-black/100"></div>
          </div>

          <div
            className={cn(`relative w-[400px] max-w-full h-full hidden sm:flex`)}
          >
            {character?.avatar_url && (
              <Image
                src={character.avatar_url}
                alt={character.name || ''}
                layout="fill"
                objectFit="cover"
                className="w-full h-full object-top"
              />
            )}

            {!showPaymentProviderChanged && showRenewalReminder && renderRenewalSubscriptionTimer()}
          </div>

          <div className="relative w-[400px] max-w-full h-full">
            {showPaymentProviderChanged && renderPaymentProviderChanged()}
            {!showPaymentProviderChanged && showRenewalReminder && renderSubscriptionRenewalReminder()}
          </div>

          <button
            onClick={onClose}
            className="absolute right-2 top-2 rounded-full bg-black/[0.5] border border-white/[0.5] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none"
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </BaseModal>
  );
};
