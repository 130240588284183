
// The following is for the subscription payment method:
// - stripe_embedded
// - stripe_hosted 

export const PaymentProvider = {
    STRIPE: "stripe",
    PAYPAL: "paypal",
    CCBILL: "ccbill",
}

export const ActivePaymentProvider = PaymentProvider.CCBILL;

export enum PaymentMethod {
    STRIPE_EMBEDDED = "stripe_embedded",
    STRIPE_HOSTED = "stripe_hosted",
    PAYPAL = "paypal",
    CCBILL = "ccbill",
}

export const SubscriptionPaymentMethod: PaymentMethod = 
    ActivePaymentProvider === PaymentProvider.CCBILL
        ? PaymentMethod.CCBILL
        : ActivePaymentProvider === PaymentProvider.STRIPE 
            ? PaymentMethod.STRIPE_EMBEDDED 
            : PaymentMethod.PAYPAL;
