import LocalStorageUtils from '@/utils/storage';
import { PropsWithChildren, useEffect, useState } from 'react';
import { toTitleCase } from '../../utils/string';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useProductsStore } from '@/stores/products';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'next/router';
import { openModal, closeModal } from '../BaseModal';
import { Modals } from '@/types/modals';
import { ProductType } from '@/types/products';
import { useAppStore } from '@/stores/app';
import { IS_ONBOARDED, PAYMENT_PROVIDER_CHANGED_SHOWN, RENEWAL_REMINDER_LAST_SHOWN } from '@/contants/storageKeys';
import SignInModal from '@/components/modals/SignIn';
import { StorageModal } from '@/components/modals/Storage';
import { ConfirmationModal } from '@/components/modals/Confirmation';
import { SuccessPaymentModal } from '@/components/modals/SuccessPayment';
import { WelcomeModal } from '@/components/modals/Welcome';
import { RenewalReminderModal } from '@/components/modals/RenewalReminder';
import { SuccessSubscriptionModal } from '@/components/modals/SuccessSubscription';
import { FailurePaymentModal } from '@/components/modals/FailurePayment';
import posthog from 'posthog-js';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ActivePaymentProvider } from '@/payment';
import { PaymentProvider } from '@/payment';
import dynamic from 'next/dynamic';

const SEVEN_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 7;
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

interface Props {
  pathName: string;
}

interface ProductCheckoutModalProps {
  onPaymentSucceeded: () => void;
  onPaymentError: (error: string) => void;
}

const ProductCheckoutModal = dynamic<ProductCheckoutModalProps>(() => {
  switch (ActivePaymentProvider) {
    case PaymentProvider.STRIPE:
      return import('@/components/modals/ProductCheckoutStripe').then(mod => mod.ProductCheckoutModal);
    case PaymentProvider.PAYPAL:
      return import('@/components/modals/ProductCheckoutPaypal').then(mod => mod.ProductCheckoutModal);
    case PaymentProvider.CCBILL:
      return import('@/components/modals/ProductCheckoutCCBill').then(mod => mod.ProductCheckoutModal);
    default:
      throw new Error(`Unsupported payment provider: ${ActivePaymentProvider}`);
  }
});

export function AppLayout({ pathName, children }: PropsWithChildren<Props>) {
  const {
    products,
    selectedProductId,
    clearSelectedProduct,
    getProductsAndPromotions,
  } = useProductsStore(s => s);
  const [checkoutErrorMsg, setCheckoutErrorMsg] = useState<string | null>(null);
  const isSignedIn = useIsAuthenticated();
  const {userStats, initUser, getUserStats} = useUserStore(s => ({userStats: s.userStats, initUser: s.initialize, getUserStats: s.getUserStats}));
  const {initCharacters, characters} = useCharactersStore(s => ({initCharacters: s.initialize, characters: s.characters}));
  const { banners, isSignInModalVisible, getBanners, setSignInUiType, setIsSignInModalVisible, confirmation, showConfirmation } = useAppStore(s => s);
  const router = useRouter();
  const isOnboarded = LocalStorageUtils.getItem<boolean>(IS_ONBOARDED);
  const [isCustomer, setIsCustomer] = useState<boolean>(false);

  const [shouldShowRenewalReminder, setShouldShowRenewalReminder] = useState<boolean>(false);
  const [showPaymentProviderChanged, setShowPaymentProviderChanged] = useState<boolean>(false);
  const [showRenewalReminder, setShowRenewalReminder] = useState<boolean>(false);


  useEffect(() => {
    if (confirmation) {
      openModal(Modals.GenericConfirmation);
    }
  }, [confirmation]);

  useEffect(() => {
    if (userStats && userStats.subscription) {
      setIsCustomer(true);
      checkRenewalReminderState();
    }
  }, [userStats]);

  useEffect(() => {
    if (router.query?.auth_required && !isSignedIn) {
      setIsSignInModalVisible(true);
      setSignInUiType('signinUI');
      openModal(Modals.SignIn);
      posthog?.capture('signin_display_app_layout_query');
    }
  }, [router.query, isSignedIn]);

  useEffect(() => {
    if (initCharacters) {
      initCharacters();
    }
  }, [initCharacters]);

  useEffect(() => {
    if (!banners?.length) getBanners();
  }, [getBanners, banners?.length]);

  useEffect(() => {
    if (isSignedIn && initUser) {
      initUser();
      if (!isOnboarded) {
        openModal(Modals.Welcome);
      }
    }
  }, [isSignedIn, isOnboarded, initUser]);
  
  useEffect(() => {
    if (isSignedIn && isOnboarded && shouldShowRenewalReminder) {
      openModal(Modals.RenewalReminder);
    }
  }, [isSignedIn, isOnboarded, shouldShowRenewalReminder]);
  
  const checkRenewalReminderState = () => {
    let shouldShowPaymentProviderChanged = false;
    let shouldShowRenewalReminder = false;
    try {
      const paymentProviderChangedShown = LocalStorageUtils.getItem(PAYMENT_PROVIDER_CHANGED_SHOWN);
      const lastShownTime: number | null = LocalStorageUtils.getItem(RENEWAL_REMINDER_LAST_SHOWN)
        ? LocalStorageUtils.getItem(RENEWAL_REMINDER_LAST_SHOWN) || 0
        : null;
      const canShowRenewalReminder = lastShownTime
        ? new Date(lastShownTime).getTime() + ONE_DAY_IN_MS < new Date().getTime()
        : true;

      const userSubscription = userStats.subscription;
      const timeToExpire = userSubscription?.current_period_end ? new Date(userSubscription.current_period_end).getTime() - new Date().getTime() : null;

      if (userSubscription?.vendor === 'stripe' && userSubscription?.status === 'active' && timeToExpire && timeToExpire > -SEVEN_DAYS_IN_MS && timeToExpire < SEVEN_DAYS_IN_MS && !paymentProviderChangedShown) {
          shouldShowPaymentProviderChanged = true;
      } else if (userSubscription?.vendor === 'stripe' && userSubscription?.status !== 'legacy' && timeToExpire && timeToExpire > -SEVEN_DAYS_IN_MS && timeToExpire < SEVEN_DAYS_IN_MS && canShowRenewalReminder) {
        shouldShowRenewalReminder = true;
      } else if (userSubscription?.vendor === 'ccbill' && userSubscription?.status === 'cancelled' && timeToExpire && timeToExpire > -SEVEN_DAYS_IN_MS && timeToExpire < 0 && canShowRenewalReminder) {
        shouldShowRenewalReminder = true;
      }
    } catch (error) {
      console.error('[DEBUG] Error checking renewal reminder state', error);
    }

    setShowPaymentProviderChanged(shouldShowPaymentProviderChanged);
    setShowRenewalReminder(shouldShowRenewalReminder);
    setShouldShowRenewalReminder(shouldShowRenewalReminder || shouldShowPaymentProviderChanged);
  };

  const onCheckoutSucceeded = async () => {
    closeModal(Modals.ProductCheckout);
    closeModal(Modals.Storage);
    await getUserStats();
    const selectedProduct = products.find(p => p.id === selectedProductId);
    if (selectedProduct?.type === ProductType.Subscription) {
      openModal(Modals.SuccessSubscription);
      posthog?.capture('purchased_subscription', {'product_id': selectedProductId});
    } else {
      openModal(Modals.SuccessPayment);
      posthog?.capture('purchase', {'product_id': selectedProductId});
    }
    await getProductsAndPromotions();
    clearSelectedProduct();
  };

  const onCheckoutError = async (error: string) => {
    setCheckoutErrorMsg(error);
    openModal(Modals.FailurePayment);
    await getProductsAndPromotions();
    clearSelectedProduct();
  };

  const closeSignInModal = () => setIsSignInModalVisible(false);

  return (
    <>
      <div className="main">{children}</div>
      <SignInModal isVisible={isSignInModalVisible} onClose={closeSignInModal}/>
      <ConfirmationModal confirmation={confirmation} onClose={() => showConfirmation(null)}/>
      <WelcomeModal />
      {isSignedIn && (
        <div>
          <StorageModal />
          {isCustomer && <RenewalReminderModal showPaymentProviderChanged={showPaymentProviderChanged} showRenewalReminder={showRenewalReminder} />}
          <PayPalScriptProvider 
            options={{ clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID }}
            deferLoading={false}
          >
            <ProductCheckoutModal onPaymentSucceeded={onCheckoutSucceeded} onPaymentError={onCheckoutError} />
            <FailurePaymentModal message={checkoutErrorMsg || 'An error occurred while processing your payment'} />
            <SuccessPaymentModal />
            <SuccessSubscriptionModal />
          </PayPalScriptProvider>
        </div>
      )}
    </>
  );
}
