import { getPublicImage } from '@/utils/image';
import { CharacterCreationChoices } from '../../../models/choices';

export interface CharacterTrait {
  id: string;
  name: string;
  asset: string | null;
  description?: string | null;
}

export interface Style extends CharacterTrait { }
export interface Ethnicity extends CharacterTrait { }
export interface Age extends CharacterTrait { }
export interface EyesColor extends CharacterTrait { }
export interface HairStyle extends CharacterTrait { }
export interface HairColor extends CharacterTrait { }
export interface BodyType extends CharacterTrait { }
export interface BreastSize extends CharacterTrait { }
export interface ButtSize extends CharacterTrait { }
export interface Clothing extends CharacterTrait { }
export interface Relationship extends CharacterTrait { }
export interface Personality extends CharacterTrait { }
export interface Voice extends CharacterTrait { }
export interface FacialExpression extends CharacterTrait { }
export interface PhysicalPosition extends CharacterTrait { }

export const styles: Style[] = [
  { id: 'realistic', name: 'Realistic', asset: getPublicImage('avatars/style_realistic.png') },
  { id: 'artistic', name: 'Anime', asset: getPublicImage('avatars/style_artistic.png') },
];

export const ethnicities: { [key: string]: Ethnicity[] } = {
    'realistic': [
      { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/realistic_caucasian_v2.png') },
      { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/realistic_latina_v2.png') },
      { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/realistic_asian.png') },
      { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/realistic_black.png') },
      { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/realistic_indian.png') },
    ],
    'artistic': [
      { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/artistic_caucasian.png') },
      { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/artistic_latina_v2.png') },
      { id: 'elf', name: 'Elf', asset: getPublicImage('character_creation/ethnicity/artistic_elf.png') },
      { id: 'devil', name: 'Devil', asset: getPublicImage('character_creation/ethnicity/artistic_devil.png') },
      { id: 'goblin', name: 'Goblin', asset: getPublicImage('character_creation/ethnicity/artistic_goblin.png') },
      { id: 'catgirl', name: 'Catgirl', asset: getPublicImage('character_creation/ethnicity/artistic_catgirl.png') },
  ]
};

// Add the test variant ethnicities
export const testEthnicities: Ethnicity[] = [
  { id: 'caucasian', name: 'Caucasian', asset: getPublicImage('character_creation/ethnicity/realistic_caucasian_v3.png') },
  { id: 'latina', name: 'Latina', asset: getPublicImage('character_creation/ethnicity/realistic_latina_v3.png') },
  { id: 'asian', name: 'Asian', asset: getPublicImage('character_creation/ethnicity/realistic_asian_v2.png') },
  { id: 'black', name: 'Black', asset: getPublicImage('character_creation/ethnicity/realistic_black_v2.png') },
  { id: 'indian', name: 'Indian', asset: getPublicImage('character_creation/ethnicity/realistic_indian_v2.png') },
];

export const ages: { [key: string]: Age[] } = {
  'realistic': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
    // { id: '50s', name: '50s', asset: null },
    // { id: '60s', name: '60s', asset: null },
  ],
  'artistic': [
    { id: '18+', name: 'Teen 18+', asset: null },
    { id: '20s', name: '20s', asset: null },
    { id: '30s', name: '30s', asset: null },
    { id: '40-55', name: '40-55', asset: null },
    // { id: '40s', name: '40s', asset: null },
    // { id: '50s', name: '50s', asset: null },
    // { id: '60s', name: '60s', asset: null },
  ]
};

export const eyesColors: { [key: string]: EyesColor[] } = {
  'realistic': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/realistic_brown_v2.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/realistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/realistic_green.png') },
  ],
  'artistic': [
    { id: 'brown', name: 'Brown', asset: getPublicImage('character_creation/eyes_color/artistic_brown.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/eyes_color/artistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/eyes_color/artistic_green.png') },
    { id: 'yellow', name: 'Yellow', asset: getPublicImage('character_creation/eyes_color/artistic_yellow.png') },
    { id: 'red', name: 'Red', asset: getPublicImage('character_creation/eyes_color/artistic_red.png') },
  ]
};

export const hairStyles: { [key: string]: HairStyle[] } = {
  'realistic': [
    { id: 'straight', name: 'Straight', asset: getPublicImage('character_creation/hair/realistic_straight_v2.png') },
    { id: 'bangs', name: 'Bangs', asset: getPublicImage('character_creation/hair/realistic_bangs_v2.png') },
    { id: 'curly', name: 'Curly', asset: getPublicImage('character_creation/hair/realistic_curly_v2.png') },
    { id: 'bun', name: 'Bun', asset: getPublicImage('character_creation/hair/realistic_bun_v2.png') },
    { id: 'short', name: 'Short', asset: getPublicImage('character_creation/hair/realistic_short_v2.png') },
    { id: 'braids', name: 'Braids', asset: getPublicImage('character_creation/hair/realistic_braids_v2.png') },
    { id: 'ponytail', name: 'Ponytail', asset: getPublicImage('character_creation/hair/realistic_ponytail_v2.png') },
    { id: 'razor', name: 'Razor Cut', asset: getPublicImage('character_creation/hair/realistic_razor_v2.png') },
    { id: 'mohawk', name: 'Mohawk', asset: getPublicImage('character_creation/hair/realistic_mohawk_v2.png') },
    { id: 'twa', name: 'TWA', asset: getPublicImage('character_creation/hair/realistic_twa_v2.png') },
  ],
  'artistic': [
    { id: 'straight', name: 'Straight', asset: getPublicImage('character_creation/hair/artistic_straight_v3.png') },
    { id: 'bangs', name: 'Bangs', asset: getPublicImage('character_creation/hair/artistic_bangs_v3.png') },
    { id: 'curly', name: 'Curly', asset: getPublicImage('character_creation/hair/artistic_curly_v3.png') },
    { id: 'bun', name: 'Bun', asset: getPublicImage('character_creation/hair/artistic_bun_v3.png') },
    { id: 'short', name: 'Short', asset: getPublicImage('character_creation/hair/artistic_short_v3.png') },
    { id: 'braids', name: 'Braids', asset: getPublicImage('character_creation/hair/artistic_braids_v3.png') },
    { id: 'ponytail', name: 'Ponytail', asset: getPublicImage('character_creation/hair/artistic_ponytail_v3.png') },
    { id: 'pigtails', name: 'Pigtails', asset: getPublicImage('character_creation/hair/artistic_pigtails_v3.png') },
    { id: 'spiky', name: 'Spiky', asset: getPublicImage('character_creation/hair/artistic_spiky_v3.png') },
    { id: 'razor', name: 'Razor Cut', asset: getPublicImage('character_creation/hair/artistic_razor_v3.png') },
  ]
};

export const hairColors: { [key: string]: HairColor[] } = {
  'realistic': [
    { id: 'blonde', name: 'Blonde', asset: getPublicImage('character_creation/hair_color/realistic_blonde.png') },
    { id: 'brunette', name: 'Brunette', asset: getPublicImage('character_creation/hair_color/realistic_brunette.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/hair_color/realistic_black.png') },
    { id: 'redhead', name: 'Redhead', asset: getPublicImage('character_creation/hair_color/realistic_redhead.png') },
    { id: 'pink', name: 'Pink', asset: getPublicImage('character_creation/hair_color/realistic_pink.png') },
    { id: 'white', name: 'White', asset: getPublicImage('character_creation/hair_color/realistic_white.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/hair_color/realistic_blue.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/hair_color/realistic_green.png') },
    { id: 'purple', name: 'Purple', asset: getPublicImage('character_creation/hair_color/realistic_purple.png') },
    { id: 'multicolor', name: 'Multicolor', asset: getPublicImage('character_creation/hair_color/realistic_multicolor.png') },
  ],
  'artistic': [
    { id: 'blonde', name: 'Blonde', asset: getPublicImage('character_creation/hair_color/artistic_blonde_v3.png') },
    { id: 'brunette', name: 'Brunette', asset: getPublicImage('character_creation/hair_color/artistic_brunette_v3.png') },
    { id: 'black', name: 'Black', asset: getPublicImage('character_creation/hair_color/artistic_black_v3.png') },
    { id: 'redhead', name: 'Redhead', asset: getPublicImage('character_creation/hair_color/artistic_redhead_v3.png') },
    { id: 'pink', name: 'Pink', asset: getPublicImage('character_creation/hair_color/artistic_pink_v3.png') },
    { id: 'white', name: 'White', asset: getPublicImage('character_creation/hair_color/artistic_white_v3.png') },
    { id: 'blue', name: 'Blue', asset: getPublicImage('character_creation/hair_color/artistic_blue_v3.png') },
    { id: 'green', name: 'Green', asset: getPublicImage('character_creation/hair_color/artistic_green_v3.png') },
    { id: 'purple', name: 'Purple', asset: getPublicImage('character_creation/hair_color/artistic_purple_v3.png') },
    { id: 'multicolor', name: 'Multicolor', asset: getPublicImage('character_creation/hair_color/artistic_multicolor_v3.png') },
  ]
};

export const bodyTypes: { [key: string]: BodyType[] } = {
  'realistic': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/realistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/realistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/realistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/realistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/realistic_body_athletic.png') },
  ],
  'artistic': [
    { id: 'slim', name: 'Slim', asset: getPublicImage('character_creation/body/artistic_body_slim.png') },
    { id: 'petite', name: 'Petite', asset: getPublicImage('character_creation/body/artistic_body_petite.png') },
    { id: 'curvy', name: 'Curvy', asset: getPublicImage('character_creation/body/artistic_body_curvy_v2.png') },
    { id: 'voluptuous', name: 'Voluptuous', asset: getPublicImage('character_creation/body/artistic_body_voluptuous.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/body/artistic_body_athletic.png') },
  ]
};

export const breastSizes: { [key: string]: BreastSize[] } = {
  'realistic': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/realistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/realistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/realistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/realistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/realistic_breasts_huge.png') },
  ],
  'artistic': [
    { id: 'flat', name: 'Flat', asset: getPublicImage('character_creation/breasts/artistic_breasts_flat.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/breasts/artistic_breasts_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/breasts/artistic_breasts_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/breasts/artistic_breasts_large.png') },
    { id: 'huge', name: 'Huge', asset: getPublicImage('character_creation/breasts/artistic_breasts_huge.png') },
  ]
};

export const buttSizes: { [key: string]: ButtSize[] } = {
  'realistic': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/realistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/realistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/realistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/realistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/realistic_butt_athletic.png') },
  ],
  'artistic': [
    { id: 'skinny', name: 'Skinny', asset: getPublicImage('character_creation/butt/artistic_butt_skinny.png') },
    { id: 'small', name: 'Small', asset: getPublicImage('character_creation/butt/artistic_butt_small.png') },
    { id: 'medium', name: 'Medium', asset: getPublicImage('character_creation/butt/artistic_butt_medium.png') },
    { id: 'large', name: 'Large', asset: getPublicImage('character_creation/butt/artistic_butt_large.png') },
    { id: 'athletic', name: 'Athletic', asset: getPublicImage('character_creation/butt/artistic_butt_athletic.png') },
  ]
};

export const clothes: Clothing[] = [
  { id: 'lingerie', name: 'Lingerie', asset: '🩱' },
  { id: 'swimwear', name: 'Swimwear', asset: '👙' },
  { id: 'clubwear', name: 'Clubwear', asset: '💃' },
  { id: 'formal', name: 'Formal', asset: '👗' },
  { id: 'businessSexy', name: 'Business Sexy', asset: '👠' },
  { id: 'leather', name: 'Leather', asset: '🧥' },
  { id: 'lace', name: 'Lace', asset: '🕸️' },
  { id: 'corset', name: 'Corset', asset: '👘' },
  { id: 'minidress', name: 'Mini Dress', asset: '👚' },
  { id: 'bodysuit', name: 'Bodysuit', asset: '🩲' },
  { id: 'schoolgirl', name: 'Schoolgirl', asset: '🎒' },
  { id: 'maid', name: 'Maid', asset: '🧹' },
  { id: 'nurse', name: 'Nurse', asset: '👩‍⚕️' },
  { id: 'catsuit', name: 'Catsuit', asset: '🐱' },
  { id: 'bikini', name: 'Bikini', asset: '🌊' }

  // { id: 'casual', name: 'Casual', asset: '👚' },
  // { id: 'formal', name: 'Formal', asset: '👗' },
  // { id: 'business', name: 'Business', asset: '🧥' },
  // { id: 'sporty', name: 'Sporty', asset: '🏃‍♀️' },
  // { id: 'swimwear', name: 'Swimwear', asset: '👙' },
  // { id: 'lingerie', name: 'Lingerie', asset: '🩱' },
  // { id: 'uniform', name: 'Uniform', asset: '👮‍♀️' },
  // { id: 'cosplay', name: 'Cosplay', asset: '🦸‍♀️' },
  // { id: 'pajamas', name: 'Pajamas', asset: '🛌' },
  // { id: 'traditional', name: 'Traditional', asset: '👘' },
  // { id: 'gothic', name: 'Gothic', asset: '🖤' },
  // { id: 'streetwear', name: 'Streetwear', asset: '🧢' },
  // { id: 'bohemian', name: 'Bohemian', asset: '🧣' },
  // { id: 'punk', name: 'Punk', asset: '🎸' },
  // { id: 'vintage', name: 'Vintage', asset: '👒' },
  // { id: 'preppy', name: 'Preppy', asset: '🎽' },
  // { id: 'grunge', name: 'Grunge', asset: '🧶' },
  // { id: 'hippie', name: 'Hippie', asset: '☮️' },
  // { id: 'cyberpunk', name: 'Cyberpunk', asset: '🤖' },
  // { id: 'steampunk', name: 'Steampunk', asset: '⚙️' },
  // { id: 'military', name: 'Military', asset: '🎖️' },
  // { id: 'rockstar', name: 'Rockstar', asset: '🎤' },
  // { id: 'beachwear', name: 'Beachwear', asset: '🏖️' },
  // { id: 'winter', name: 'Winter', asset: '🧤' }
];

export const personalities: Personality[] = [
  { id: 'Caregiver', name: 'Caregiver', description: 'Nurturing, protective, and always there to offer comfort.', asset: '🤗' },
  { id: 'Innocent', name: 'Innocent', description: 'Optimistic, naive, and sees world with wonder.', asset: '😇' },
  { id: 'Lover', name: 'Lover', description: 'Romantic, affectionate, and cherishes deep emotional connection.', asset: '❤️' },
  { id: 'Submissive', name: 'Submissive', description: 'Obedient, yielding, and happy to follow.', asset: '🙇‍♀️' },
  { id: 'Dominant', name: 'Dominant', description: 'Assertive, controlling, and commanding.', asset: '👑' },
  { id: 'Temptress', name: 'Temptress', description: 'Flirtatious, playful, and always leaving you wanting more.', asset: '️💋' },
  { id: 'Nympho', name: 'Nympho', description: 'Intensely enthusiastic, sensual, and always ready for intimacy.', asset: '💦' },
  { id: 'Mean', name: 'Mean', description: 'Cold, dismissive, and often sarcastic.', asset: '😡' },
  { id: 'Diva', name: 'Diva', description: 'High-maintenance, demanding, and expects to be treated like royalty.', asset: '👸' },
  { id: 'Tsundere', name: 'Tsundere', description: 'Hostile and dismissive at first, but gradually warms up... maybe.', asset: '😤' },
  { id: 'Insane', name: 'Insane', description: 'Unpredictable, chaotic, and detached from reality. Proceed with caution.', asset: '🤪' },
  { id: 'Yandere', name: 'Yandere', description: 'Sweet and loving on the surface, but possessive and potentially dangerous.', asset: '🔪' }
];

export const voices: Voice[] = [
  { id: '1', name: 'Voice 1', asset: null },
  { id: '5', name: 'Voice 2', asset: null },
  { id: '3', name: 'Voice 3', asset: null },
  { id: '4', name: 'Voice 4', asset: null },
  { id: '2', name: 'Voice 5', asset: null },
];

export const relationships: Relationship[] = [
  { id: 'stranger', name: 'Stranger', asset: '🕵️‍♀️' },
  { id: 'classmate', name: 'Classmate', asset: '📚' },
  { id: 'coworker', name: 'Co-worker', asset: '💼' },
  { id: 'teacher', name: 'Teacher', asset: '👩‍🏫' },
  { id: 'girlfriend', name: 'Girlfriend', asset: '🩷' },
  { id: 'fwb', name: 'Friends with Benefits', asset: '🔥' },
  { id: 'wife', name: 'Wife', asset: '💍' },
  { id: 'mistress', name: 'Mistress', asset: '🌙' },
  { id: 'acquaintance', name: 'Acquaintance', asset: '🤝' },
  { id: 'bestfriend', name: 'Best Friend', asset: '🤞' },
  { id: 'stepsister', name: 'Step Sister', asset: '👯‍♀️' },
  { id: 'stepmom', name: 'Step Mom', asset: '👩‍👦' },
];

export const facialExpressions: FacialExpression[] = [
  { id: 'angry', name: 'Angry', asset: '😠', description: 'angry,' },
  { id: 'happy', name: 'Happy', asset: '😊', description: 'happy,' },
  { id: 'teasing', name: 'Teasing', asset: '😍', description: 'naughty and teasing facial expression,' },
  { id: 'shocked', name: 'Shocked', asset: '😲', description: 'shocked facial expression,' },
  { id: 'moaning', name: 'Moaning', asset: '🥵', description: 'moaning, eyes closed,' },
  { id: 'ahegao', name: 'Ahegao', asset: '🥵', description: '(ahegao),' },  
];

export const physicalPositions: PhysicalPosition[] = [
  { id: 'lying down', name: 'Lying Down', asset: '🛏️', description: 'lying down,' },
  { id: 'kneeling', name: 'Kneeling', asset: '🧎‍♀️', description: 'kneeling,' },
  { id: 'sitting', name: 'Sitting', asset: '🪑', description: 'sitting,' },
  { id: 'standing', name: 'Standing', asset: '🧍‍♀️', description: 'standing,' },
  { id: 'sex', name: 'Having Sex', asset: '💋', description: 'having sex,' },
  { id: 'blowjob', name: 'Blowjob', asset: '☝️', description: 'blowjob,' },
  { id: 'masturbating', name: 'Masturbating', asset: '☝️', description: 'masturbating,' },
  { id: 'doggy style', name: 'Doggy Style', asset: '☝️', description: 'doggy style sex,' },
  { id: 'anal', name: 'Anal Sex', asset: '☝️', description: 'anal sex,' },
  { id: 'bound and gagged', name: 'Bound and Gagged', asset: '☝️', description: 'arms tied behind back, mouth gagged,' },
  { id: 'cumshot', name: 'Cumshot', asset: '☝️', description: 'cum on face,' },
];

export const viewAngles: CharacterTrait[] = [
  { id: 'medium shot', name: 'Medium Shot', asset: '🛏️', description: 'medium shot, dynamic angle,' },
  { id: 'from above', name: 'From Above', asset: '🧎‍♀️', description: 'view from above,' },
  { id: 'from behind', name: 'From Behind', asset: '🪑', description: 'from from behind,' },
  { id: 'fullbody', name: 'Full Body Shot', asset: '🧍‍♀️', description: 'full body view,' },
];

export const environments: CharacterTrait[] = [
  { id: 'Bedroom', name: 'Bedroom', asset: '🛏️', description: 'in a bedroom,' },
  { id: 'Kitchen', name: 'Kitchen', asset: '🛁', description: 'in a kitchen,' },
  { id: 'Gym', name: 'Gym', asset: '🏋️‍♀️', description: 'in a gym,' },
  { id: 'Office', name: 'Office', asset: '�', description: 'in an office,' },
  { id: 'classroom', name: 'Classroom', asset: '🏫', description: 'in a school classroom,' },
  { id: 'Forest', name: 'Forest', asset: '🌲', description: 'in a forest,' },
  { id: 'Beach', name: 'Beach', asset: '🏖️', description: 'on a beach,' },
  { id: 'Warehouse', name: 'Warehouse', asset: '🏜️', description: 'in a dark abandoned warehouse,' },
];

export const customClothes: Clothing[] = [
  { id: 'lingerie', name: 'Lingerie', asset: '🩱', description: 'wearing lacy lingerie,' },
  { id: 'naked', name: 'Naked', asset: '🩱', description: 'naked,' },
  { id: 'topless', name: 'Topless', asset: '🩱', description: 'topless,' },
  { id: 'sundress', name: 'Sundress', asset: '🩱', description: 'wearing a sundress,' },
  { id: 'bikini', name: 'Bikini', asset: '🩱', description: 'wearing a bikini,' },
  { id: 'teacher', name: 'Teaching Outfit', asset: '🩱', description: 'wearing a pencil skirt and a white blouse, teaching in front of the class,' },
  { id: 'sport', name: 'Sporty', asset: '🩱', description: 'wearing sporty clothing, sweaty,' },
  { id: 'shibari', name: 'Shibari', asset: '🩱', description: 'wearing shibari outfit,' },
  { id: 'schoolgirl', name: 'School Girl', asset: '🩱', description: 'wearing schoolgirl outfit,' },
  { id: 'corset', name: 'Corset', asset: '🩱', description: 'wearing a corset,' },
];

export const promptExamples = {
  'Dinner date': 'wearing a pencil dress, sitting at a dinner table at a fancy restaurant, dim lighting',
  'Wet and wild': 'wearing bikini, sweaty, dripping wet, standing, at the beach',
  'Sexy time': 'naked, lying on back, vaginal sex, oiled up, eyes closed, moaning, in bedroom',
  'Write your own': '',
}

type CharacterTraitList = CharacterTrait[];
type CharacterTraitDictionary = { [key: string]: CharacterTrait[] };

const choicesByName: { [key: string]: CharacterTraitList | CharacterTraitDictionary | null} = {
  'style': styles,
  'ethnicity': ethnicities,
  'age': ages,
  'eyesColor': eyesColors,
  'hairStyle': hairStyles,
  'hairColor': hairColors,
  'bodyType': bodyTypes,
  'breastSize': breastSizes,
  'buttSize': buttSizes,
  'personality': personalities,
  'relationship': relationships,
  'clothing': clothes,
  'voice': voices,
  'facialExpression': facialExpressions,
  'physicalPosition': physicalPositions,
  'customClothes': customClothes,
  'viewAngle': viewAngles,
  'environment': environments,
}

export const getCreationChoicesByName = (style: string, choiceName: keyof CharacterCreationChoices) => {
  if (!(choiceName in choicesByName)) {
    return null;
  }
  const choices = choicesByName[choiceName];
  if (Array.isArray(choices)) {
    return choices;
  }
  if (choices && typeof choices === 'object') {
    return choices[style] || null;
  }
  return null;
}


export const getCharacterNameSuggestion = () => {
  const suggestions = [
    "Luna", "Zoe", "Aria", "Nova", "Chloe", "Mia", "Ava", "Lily", "Emma", "Sophia",
    "Isla", "Everly", "Hazel", "Aurora", "Willow", "Ivy", "Sage", "Skye", "River", "Remi",
    "Quinn", "Piper", "Nora", "Zara", "Aspen", "Ember", "Juniper", "Sarah", "Rachel", "Hailey"
  ];
  return suggestions[Math.floor(Math.random() * suggestions.length)];
};
