import { NextSeo } from 'next-seo';
import { GetServerSideProps } from 'next';
import { AnonymousBanner } from '@/components/AnonymousBanner';
import { SidebarLayout } from '@/components/layout/SidebarLayout';
import { Text } from '@/components/Text';
import { CharactersList } from '@/components/page-components/home/CharactersList';
import { CharectersListSkeleton } from '@/components/page-components/home/CharectersListSkeleton';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useAppStore } from '@/stores/app';
import { BannersCarousel } from '@/components/page-components/home/BannersCarousel';
import { Footer } from '@/components/Footer';
import { StaggeredContentLayout } from '@/components/page-components/layout/StaggeredContentLayout';
import { IconCardGrid } from '@/components/page-components/layout/IconCardGrid';
import { AccordionList } from '@/components/page-components/layout/AccordionList';
import { RefreshCw, MessageCirclePlus, Image, Lock } from 'lucide-react';
import { useRouter } from 'next/navigation';

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const protocol: string = process.env.NODE_ENV === 'production' ? 'https' : 'http';
  const baseUrl: string = `${protocol}://${req.headers.host}`;

  return {
    props: {
      canonicalUrl: baseUrl,
    },
  };
};

export default function Home({ canonicalUrl }: { canonicalUrl: string }) {
  const { characters, loading } = useCharactersStore(s => ({
    characters: s.characters,
    loading: s.loading,
  }));
  const systemCharacters = characters.filter(c => c.created_by_user === false);
  const { banners, bannersLoading } = useAppStore(s => s);
  const isLoggedIn = useIsAuthenticated();
  const router = useRouter();

  const staggeredSections = [
    {
      title: "The Ultimate NSFW AI Chat Experience",
      subtitle: "Where AI gets naughty and your fantasies come to life",
      description: "Tired of basic AI chatbots that can't handle spicy content? ThotChat is different. Our AI characters are specifically designed for NSFW conversations, custom image generation, and exploring your wildest fantasies. No censorship, no judgment, just pure uncensored fun with AI companions who are always ready to play.\n\nWhether you're looking for a steamy sexting session, want to explore specific kinks, or just want to chat with someone who won't judge your desires, our AI characters are here for you. Plus, with our advanced image generation, you can actually see your fantasies come to life!",
      buttonText: "Start Chatting Now",
      buttonAction: () => router.push('/chat'),
      imageSrc: "https://images.thotchat.ai/seo/ai-chat-preview.png",
      imageAlt: "AI Chat Preview"
    }
  ];

  const featuresContent = {
    sectionTitle: "Why ThotChat Dominates NSFW AI",
    sectionSubtitle: "The spiciest AI experience available",
    cards: [
      {
        icon: <Lock className="w-6 h-6 text-white" />,
        title: "100% Private & Secure",
        description: "Your chats and generated content stay completely private. What happens in ThotChat, stays in ThotChat."
      },
      {
        icon: <Image className="w-6 h-6 text-white" />,
        title: "Custom NSFW Images",
        description: "Generate unique, personalized NSFW images of your AI companions. Your fantasies, visualized."
      },
      {
        icon: <RefreshCw className="w-6 h-6 text-white" />,
        title: "Unlimited Scenarios",
        description: "From vanilla to kinky, our AIs can handle any scenario you throw at them. No judgment, no limits."
      },
      {
        icon: <MessageCirclePlus className="w-6 h-6 text-white" />,
        title: "Smart & Responsive",
        description: "Our AIs remember your preferences and adapt to your style, creating truly personalized experiences."
      }
    ]
  };

  const faqContent = {
    sectionTitle: "Spicy FAQ",
    sectionSubtitle: "Everything you want to know about NSFW AI chat",
    items: [
      {
        id: 'nsfw-limits',
        question: "How NSFW can the chats get?",
        answer: "Very. Our AI characters are designed to handle explicit content and can engage in everything from light flirting to shit that's so kinky you'll unlock more new fetishes than you can imagine. They're also great at sexting and can generate custom NSFW images on demand."
      },
      {
        id: 'image-gen',
        question: "How do custom images work?",
        answer: "Our advanced AI can generate unique NSFW images of your chat partners based on your descriptions and preferences. Want to see your AI girlfriend in specific outfits or situations? Just ask!"
      },
      {
        id: 'privacy',
        question: "Is my content secure?",
        answer: "Absolutely. All data is completely secure. We don't share your conversations with anyone."
      },
      {
        id: 'customization',
        question: "Can I create custom characters?",
        answer: "Yes! You can create and customize your own AI characters with specific personalities, kinks, and preferences. You can also extensively customize their appearance. The possibilities are endless!"
      }
    ]
  };

  return (
    <>
      <NextSeo
        title="ThotChat - NSFW AI Chat"
        description="Chat with NSFW AI characters in uncensored scenarios"
        canonical={canonicalUrl}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: canonicalUrl,
          title: 'ThotChat - NSFW AI Chat',
          description: 'NSFW AI girlfriends and porn that are fully customizable and dirtier than a keyboard at a public library',
          site_name: 'ThotChat',
        }}
      />
      <SidebarLayout>
        <div className="flex flex-col min-h-[calc(100dvh-60px)] justify-between">
          <div className={'mt-1'}>
            {banners && banners.length > 0 && (
              <BannersCarousel banners={banners} loading={bannersLoading} />
            )}
          </div>
          <div className="flex flex-col">
            <Text 
              className="mt-8 mb-5 text-h5 md:text-h2 text-center" 
              variant="h3" 
              textColor="white" 
              element="h1"
            >
              ✨ Explore AI Characters ✨
            </Text>
            <div className="min-h-[calc(100vh)]"> {/* Reserve space for characters list */}
              {systemCharacters && <CharactersList characters={systemCharacters} />}
              {!systemCharacters?.length && loading && <CharectersListSkeleton />}
            </div>
            <div className="pt-16 md:pt-24">
              <StaggeredContentLayout sections={staggeredSections} />
            </div>
            <div className="pt-16 md:pt-24">
              <IconCardGrid content={featuresContent} />
            </div>
            <div className="pt-16 md:pt-24">
              <AccordionList content={faqContent} />
            </div>
            {!isLoggedIn && !loading && <AnonymousBanner />}
          </div>

          <Footer />
        </div>
      </SidebarLayout>
    </>
  );
}
