import { FC } from 'react';
import Image from 'next/image';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';
import crystalImage from '@/assets/images/crystal-image.png';
import { Plus } from 'lucide-react';
import { AnimatedCounter } from '@/components/AnimatedCounter';
import { useUserStore } from '@/stores/user';
import useIsMobile from '@/hooks/useIsMobile';

type Props = {
  className?: string;
  amount: number;
  onClick: () => void;
};

export const GemButton: FC<Props> = ({ className, amount, onClick }) => {
  const { userStats } = useUserStore(s => s);
  const isMobile = useIsMobile();

  return (
    <button
      onClick={onClick}
      className={cn(
        'inline-flex items-center justify-center text-sm font-medium text-white bg-black-200 border border-white/20 rounded-md',
        'hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        isMobile ? 'px-2 py-1.5' : 'px-4 py-2',
        className
      )}
    >
      <div className={cn(
        "flex items-center justify-center relative",
        isMobile ? "w-[18px] h-[18px] mr-1.5" : "w-[24px] h-[24px] mr-2"
      )}>
        <Image 
          className={isMobile ? "w-[16px] h-[16px]" : "w-[20px] h-[20px]"} 
          src={crystalImage} 
          alt="crystal" 
        />
      </div>
      <Text className={cn(
        "transition-all duration-150 text-white group-active/button:text-black",
        isMobile ? "text-sm" : ""
      )} variant="btn-1">
        <AnimatedCounter amount={amount} />
      </Text>
      <Plus className={cn("ml-2 -mr-1", isMobile ? "w-3 h-3" : "w-4 h-4")} />
    </button>
  );
};
