import { FC, useEffect, useState } from 'react';
import { Bell } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import useIsMobile from '@/hooks/useIsMobile';

type Props = {
  className?: string;
};

export const BeamerButton: FC<Props> = ({ className }) => {
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    window.beamer_config = {
      product_id: 'yTwcsIgn73025',
      display: 'sidebar',
      selector: '#beamer-trigger',
      button: false,
      overlay: false,
      notification_prompt: false,
      position: 'right',
      width: '100%',
      onLoad: function(data: any) {
        if (data && data.unread_posts) {
          setUnreadCount(data.unread_posts);
        }
      }
    };

    const script = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.defer = true;
    script.onload = () => {
      // Wait for Beamer to be fully initialized
      setTimeout(() => {
        if (window.Beamer?.subscribe) {
          window.Beamer.subscribe('unread_changed', (data: any) => {
            if (data && data.unread_posts !== undefined) {
              setUnreadCount(data.unread_posts);
            }
          });
        }
      }, 1000);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = () => {
    if (window.Beamer?.show) {
      window.Beamer.show();
    }
  };

  return (
    <button
      id="beamer-trigger"
      onClick={handleClick}
      className={cn(
        'inline-flex items-center justify-center text-sm font-medium text-white bg-black-200 border border-white/20 rounded-md h-[36px]',
        'hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        isMobile ? 'px-2' : 'px-4',
        className
      )}
    >
      <div className="relative">
        <Bell className={cn("text-white", isMobile ? "w-4 h-4" : "w-5 h-5")} />
        {unreadCount > 0 && (
          <div className={cn(
            "absolute bg-pink rounded-full flex items-center justify-center",
            isMobile ? "-top-1.5 -right-1.5 w-3 h-3" : "-top-2 -right-2 w-4 h-4"
          )}>
            <Text className={cn("text-white", isMobile ? "text-[10px]" : "text-xs")} variant="body-2">
              {unreadCount}
            </Text>
          </div>
        )}
      </div>
    </button>
  );
}; 