import api, { callApi } from './config';
import { IError } from '@/models/common';
import { GetProductsAndPromotionsResponse, ClaimDailyGemsResponse, PaymentCheckoutResponse, VerifyPurchaseResponse } from '@/types/products';
import { UserSubscription } from '@/models/user';

const productsApi = {
  async getProductsAndPromotions(payment_provider: string) {
    const url = `/store/catalog/web/${payment_provider}`;
    return callApi<GetProductsAndPromotionsResponse, IError>(api.get(url));
  },
  async claimDailyGems() {
    const url = '/store/claim_gems';
    return callApi<ClaimDailyGemsResponse, IError>(api.post(url));
  },
  async cancelSubscription(notes?: string) {
    const url = `/payment/cancel_subscription`;
    return callApi<UserSubscription, IError>(api.post(url, { notes }));
  },

  // Stripe
  async checkoutProductStripe(product_id: number, base_url?: string) {
    const url = base_url ? `/payment/stripe/checkout/${product_id}?base_url=${base_url}` : `/payment/stripe/checkout/${product_id}`;
    return callApi<PaymentCheckoutResponse, IError>(api.get(url));
  },
  async verifyPurchaseStripe(session_id: string) {
    const url = `/payment/stripe/verify/${session_id}`;
    return callApi<VerifyPurchaseResponse, IError>(api.get(url));
  },

  // Paypal
  async checkoutProductPaypal(product_id: number, base_url?: string) {
    const url = base_url ? `/payment/paypal/checkout/${product_id}?base_url=${base_url}` : `/payment/paypal/checkout/${product_id}`;
    return callApi<PaymentCheckoutResponse, IError>(api.get(url));
  },
  async verifyPurchasePaypal(order_id: string, subscription_id?: string) {
    const url = subscription_id ? `/payment/paypal/verify/${order_id}?subscription_id=${subscription_id}` : `/payment/paypal/verify/${order_id}`;
    return callApi<VerifyPurchaseResponse, IError>(api.get(url));
  },

  // CCBill
  async checkoutProductCCBill(product_id: number, base_url?: string) {
    const url = base_url ? `/payment/ccbill/checkout/${product_id}?base_url=${base_url}` : `/payment/ccbill/checkout/${product_id}`;
    return callApi<PaymentCheckoutResponse, IError>(api.get(url));
  },
  async verifyPurchaseCCBill(order_id: string, form_digest: string) {
    const url = `/payment/ccbill/verify/${order_id}?form_digest=${form_digest}`;
    return callApi<VerifyPurchaseResponse, IError>(api.get(url));
  },

};

export default productsApi;
